import React, { useEffect, useState } from "react";
import { makeStyles, Button } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

import clsx from "clsx";

import LogoMobile from "../../images/logo-mobile.svg";
import MobileMenu from "../../images/mobile-menu.svg";
import HeroMobile from "../../images/hero-mobile.png";
import HeroDesktop from "../../images/hero-desktop.png";
import HeroFooter from "../../images/hero-footer.svg";

import PrepareSection from "../../images/prepare-section.svg";
import RegisterSection from "../../images/register-section.svg";
import BuildSection from "../../images/build-section.svg";
import QrSection from "../../images/qr-section.svg";
import SectionsFooter from "../../images/sections-footer.svg";

import PricingBackground from "../../images/pricing-background.svg";
import PricingMobile from "../../images/pricing-mobile.png";
import PricingDesktop from "../../images/pricing-desktop.png";
import PricingFooter from "../../images/pricing-footer.svg";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: "#ff7733",
    },
    container: {
      padding: 30,
      [theme.breakpoints.up("sm")]: {
        display: "flex",
      },
    },
    title: {
      fontSize: 40,
      fontWeight: 800,
      lineHeight: "40px",
      color: "#031c40",
      [theme.breakpoints.up("sm")]: {
        fontSize: 70,
        lineHeight: "70px",
        marginBottom: 0,
      },
    },
    p: {
      color: "#031C40",
      fontSize: 15,
      [theme.breakpoints.up("sm")]: {
        fontSize: 20,
      },
    },
    header: {
      background: "#ff7733",
      display: "flex",
      justifyContent: "space-between",
      position: "absolute",
      width: "100%",
    },
    hero: {
      "&::before": {
        display: "table",
        clear: "both",
        content: '" "',
      },
      height: "100vh",
      paddingTop: 100,
      background: "#ff7733",
      [theme.breakpoints.up("sm")]: {
        alignItems: "center",
      },
    },
    heroTitle: {
      marginTop: 0,
    },
    heroDescription: {
      color: "#ffffea",
      [theme.breakpoints.up("sm")]: {
        fontSize: 20,
      },
    },
    heroContent: {
      [theme.breakpoints.up("sm")]: {
        maxWidth: 500,
        margin: "0 auto",
      },
    },
    heroFooter: {
      backgroundImage: `url(${HeroFooter})`,
      width: "100%",
      height: "10vw",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundColor: "#ffffea",
      backgroundPosition: "center -1px",
    },
    button: {
      backgroundColor: "#031c40",
      color: "#ffffea",
      borderRadius: 40,
      textTransform: "none",
      boxShadow: "7px 7px 0px 0px rgba(0,0,0,0.14)",
      minWidth: 180,
      [theme.breakpoints.up("sm")]: {
        fontSize: 20,
        width: 260,
      },
    },
    heroImageContainer: {
      marginTop: 30,
      textAlign: "center",
    },
    heroImage: {
      width: "100%",
    },
    sectionsContainer: {
      backgroundColor: "#ffffea",
      paddingTop: 100,
    },
    pinnedSection: {
      [theme.breakpoints.up("sm")]: {
        maxWidth: 500,
        // width: 500,
        margin: "0 auto",
      },
    },
    sections: {},
    col50: {
      [theme.breakpoints.up("sm")]: {
        width: "50%",
      },
    },
    section: {
      marginTop: 75,
      paddingTop: 150,
      backgroundPosition: "top",
      backgroundRepeat: "no-repeat",
      [theme.breakpoints.up("sm")]: {
        height: 600,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        backgroundSize: "contain",
        margin: "0 auto",
        padding: 0,
        maxWidth: 600,
      },
    },
    sectionTitle: {
      fontSize: 30,
      lineHeight: "30px",
      marginBottom: 0,
    },
    sectionsFooter: {
      backgroundImage: `url(${SectionsFooter})`,
      width: "100%",
      height: "10vw",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundColor: "#ffffea",
      backgroundPosition: "center bottom",
      marginBottom: "-1px",
    },
    pricingFooter: {
      backgroundImage: `url(${PricingFooter})`,
      width: "100%",
      height: "10vw",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundColor: "#ff7733",
      backgroundPosition: "center -1px",
    },
    orange: {
      color: "#ff7733",
    },
    btnOrange: {
      backgroundColor: "#ff7733",
    },
    pricingContainer: {
      background: "#031c40",
      [theme.breakpoints.up("sm")]: {
        height: "100vh",
        alignItems: "center",
        backgroundImage: `url(${PricingBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top right",
        backgroundSize: "1100px",
      },
    },
    pricingContent: {
      [theme.breakpoints.up("sm")]: {
        maxWidth: 500,
        margin: "0 auto",
      },
    },
    input: {
      "&::-webkit-input-placeholder": {
        textAlign: "center",
        color: "#031c40",
      },
      "&:-moz-placeholder": {
        /* Firefox 18- */ textAlign: "center",
        color: "#031c40",
      },
      "&::-moz-placeholder": {
        /* Firefox 19+ */ textAlign: "center",
        color: "#031c40",
      },
      "&:-ms-input-placeholder": {
        textAlign: "center",
        color: "#031c40",
      },
      backgroundColor: "#ffffea",
      border: "none",
      display: "block",
      width: "100%",
      margin: "10px 0px",
      padding: 15,
      borderRadius: 10,
      fontFamily: "Catamaran",
      color: "#031c40",
    },
    desktopHidden: {
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    mobileHidden: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "initial",
      },
    },
    contactContainer: {
      display: "block",
      maxWidth: 600,
      margin: "0 auto",
      textAlign: "center",
    },
  };
});

function HomePage(props) {
  const classes = useStyles();
  const sections = [
    {
      title: "Prepare all the content",
      content:
        "Organize everything from your menu, text and photos, in a folder. This in order to streamline your entire process.",
      image: PrepareSection,
    },
    {
      title: "Register your restaurant in Minucs",
      content:
        "Registration is very simple, we only need your full name, the name of your business and an email.",
      image: RegisterSection,
    },
    {
      title: "Build your menu",
      content:
        "The fun part. It's very simple, just write and upload the images of your menu items.",
      image: BuildSection,
    },
    {
      title: "Generate the QR code",
      content:
        "Once your menu is ready, save it and generate your QR code and start using it in your restaurant.",
      image: QrSection,
    },
  ];

  useEffect(() => {
    let controller;
    let scene;
    if (isWidthUp("sm", props.width)) {
      const ScrollMagic = require("scrollmagic");
      controller = new ScrollMagic.Controller();
      scene = new ScrollMagic.Scene({
        triggerElement: "#section-0--title",
        duration: 1800,
      })
        .setPin("#pinned")
        .addTo(controller);
    }
    return () => {
      if (scene) {
        scene.destroy();
        scene = null;
        controller.destroy();
        controller = null;
      }
    };
  }, []);

  return (
    <div className={classes.root}>
      <div className={clsx(classes.container, classes.header)}>
        <img className={clsx(classes.desktopHidden)} src={LogoMobile} />
        <img className={clsx(classes.desktopHidden)} src={MobileMenu} />
      </div>

      <div className={clsx(classes.container, classes.hero)}>
        <div className={classes.col50}>
          <div className={classes.heroContent}>
            <h1 className={clsx(classes.heroTitle, classes.title)}>
              Create your Own Digital Menu.
            </h1>
            <div className={classes.heroDescription}>
              <p>
                Forget about scanning or taking a photo of your menu to digitize
                it. Create and customize your own menu in a few minutes and
                share it with your guests.
              </p>
            </div>
            <Button
              variant="contained"
              classes={{
                root: classes.button,
              }}
              component="a"
              href="https://app.minucs.com/register"
            >
              Create your menu
            </Button>
          </div>
        </div>
        <div className={clsx(classes.heroImageContainer, classes.col50)}>
          <img
            className={clsx(classes.desktopHidden, classes.heroImage)}
            src={HeroMobile}
          />
          <img
            className={clsx(classes.mobileHidden, classes.heroImage)}
            src={HeroDesktop}
          />
        </div>
      </div>

      <div className={classes.heroFooter}></div>

      <div className={clsx(classes.container, classes.sectionsContainer)}>
        <div id="pinned" className={clsx(classes.col50)}>
          <div className={classes.pinnedSection}>
            <h2 className={classes.title}>How does it work?</h2>
            <p className={classes.p}>
              Organize everything from your menu, text and photos, in a folder.
              This in order to streamline your entire process.
            </p>
            <Button
              variant="contained"
              classes={{
                root: classes.button,
              }}
              component="a"
              href="https://app.minucs.com/register"
            >
              Start here
            </Button>
          </div>
        </div>
        <div id="sections" className={clsx(classes.col50, classes.sections)}>
          {sections.map((s, idx) => {
            return (
              <div
                id={`section-${idx}`}
                key={s.image}
                className={classes.section}
                style={{ backgroundImage: `url(${s.image})` }}
              >
                <h3
                  id={`section-${idx}--title`}
                  className={clsx(classes.title, classes.sectionTitle)}
                >
                  {s.title}
                </h3>
                <p className={clsx(classes.p, classes.sectionContent)}>
                  {s.content}
                </p>
              </div>
            );
          })}
        </div>
      </div>

      <div className={classes.sectionsFooter}></div>

      <div className={clsx(classes.container, classes.pricingContainer)}>
        <div className={classes.col50}>
          <div className={classes.pricingContent}>
            <h2 className={clsx(classes.title, classes.orange)}>
              What is the cost?
            </h2>
            <p className={clsx(classes.p, classes.orange)}>
              The annual cost is 60 USD. Unlimited changes and unlimited menus
              for same restaurant chain.
            </p>
            <Button
              variant="contained"
              classes={{
                root: clsx(classes.button, classes.btnOrange),
              }}
              component="a"
              href="https://app.minucs.com/register"
            >
              Sign up here
            </Button>
          </div>
        </div>
        <div className={classes.col50}>
          <img
            className={clsx(classes.desktopHidden, classes.heroImage)}
            style={{ maxWidth: "100%" }}
            src={PricingMobile}
          />
          <img
            className={clsx(classes.mobileHidden, classes.heroImage)}
            src={PricingDesktop}
          />
        </div>
      </div>
      <div className={classes.pricingFooter}></div>

      {/* <div className={clsx(classes.container, classes.contactContainer)}>
        <h2 className={clsx(classes.title)}>¿Aún tienes dudas?</h2>
        <p className={classes.p}>
          Pregunta lo que quieras, te responderemos lo antes posible.
        </p>
        <form>
          <input
            className={classes.input}
            type="text"
            placeholder="Nombre Completo"
          ></input>
          <input
            className={classes.input}
            type="text"
            placeholder="Correo electrónico"
          ></input>
          <textarea
            className={classes.input}
            rows="5"
            placeholder="Cuéntanos tus dudas"
          ></textarea>
        </form>
        <Button
          variant="contained"
          classes={{
            root: classes.button,
          }}
        >
          Enviar
        </Button>
      </div> */}
      <div className={classes.container}>
        <p className={classes.p}>
          Minucs. 2021. All rights reserved
          <br />
          Privacy Policy
        </p>
      </div>
    </div>
  );
}

export default withWidth()(HomePage);

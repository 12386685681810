import React from "react";
import { CssBaseline } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
// import appearance from "../../appearance";

function App(props) {
    const { children } = props;
  return (
    <MuiThemeProvider>
      <CssBaseline />
      { children }
    </MuiThemeProvider>
  );
}

export default App;
